<template>
  <div class="forget-password">
    <header class="header">
      <LogoIcon color="white" />
    </header>
    <main class="box">
      <ValidationObserver ref="resetPasswordRef">
        <section class="title">Passwort zurücksetzen</section>
        <section class="content">
          <div class="info">Legen Sie Ihr neues Passwort fest!</div>
          <div class="inputs">
            <InputElement
              v-model="newPassword"
              type="password"
              placeholder="Neues Passwort"
              validation-rules="required|min:8"
              validation-name="newPassword"
              validation-provider-tag="div"
            />
            <InputElement
              v-model="newPasswordConfirm"
              type="password"
              placeholder="Wiederhole das neue Passwort"
              validation-rules="password:@newPassword"
              validation-name="confirmPassword"
              validation-provider-tag="div"
            />
          </div>
        </section>

        <ResponseAlert
          v-if="$store.getters.errorResponse.action == 'resetPassword'"
          :response="$store.getters.errorResponse.data"
          class="mt-3"
        ></ResponseAlert>

        <section class="actions mt-3">
          <ButtonElement
            @click="$router.push({ name: 'Login', params: { role } })"
            orange-secondary
          >
            Abbrechen
          </ButtonElement>
          <ButtonElement
            @click="handleResetPassword(role)"
            :loading="$store.getters.loading"
            orange
          >
            Senden
          </ButtonElement>
        </section>
      </ValidationObserver>
    </main>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from '@vue/composition-api';
import router from '../router';
import LogoIcon from '../components/Svgs/LogoIcon.vue';
import InputElement from '../components/FormControl/InputElement.vue';
import ButtonElement from '../components/FormControl/ButtonElement.vue';
import store from '../store';

export default {
  setup(props) {
    const newPassword = ref('');
    const newPasswordConfirm = ref('');
    const resetPasswordRef = ref(null);

    const handleResetPassword = async role => {
      const valid = await resetPasswordRef.value.validate();
      if (!valid) return;

      const query = {
        passwordReseted: 1,
      };

      switch (role) {
        case 'admin':
          store
            .dispatch('resetPasswordCompanyUser', {
              email: props.email,
              token: props.token,
              password: newPassword.value,
              confirmPassword: newPasswordConfirm.value,
            })
            .then(() => {
              router.push({ name: 'Login', params: { role: 'admin' }, query });
            });
          break;
        case 'staff':
          store
            .dispatch('resetPasswordStaffUser', {
              email: props.email,
              token: props.token,
              password: newPassword.value,
              confirmPassword: newPasswordConfirm.value,
            })
            .then(() => {
              router.push({ name: 'Login', params: { role: 'staff' }, query });
            });
          break;
        case 'user':
          store
            .dispatch('resetPasswordEndUser', {
              email: props.email,
              token: props.token,
              password: newPassword.value,
              confirmPassword: newPasswordConfirm.value,
            })
            .then(() => {
              router.push({ name: 'Login', params: { role: 'user' }, query });
            });
          break;
      }
    };

    return {
      newPassword,
      newPasswordConfirm,
      handleResetPassword,
      resetPasswordRef,
    };
  },
  created() {
    this.$store.commit('setErrorResponse', {});
  },
  components: {
    ButtonElement,
    LogoIcon,
    InputElement,
  },
  props: {
    role: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.forget-password {
  padding: 20px;
  background-position: center;
  background-size: cover;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('../assets/pics/pexels-fauxels-3184634.png');
}
.box {
  background-color: white;
  border-radius: 10px;
  width: 460px;
  margin: 0 auto;
  padding: 32px 40px;
}
.title {
  color: $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  display: flex;
  justify-content: center;
}
.info {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-dark-grey;
  margin-top: $hty-margin-low;
  text-align: center;
}
.inputs {
  margin-top: $hty-margin-low;

  > :last-child {
    margin-top: $hty-margin-low;
  }
}
.actions {
  display: flex;
  justify-content: space-between;

  > * {
    flex-grow: 1;
  }

  > :first-child {
    margin-right: 10px;
  }
}
</style>
